import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import Input from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { editData, objectToFormData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { DangerRight } from "../../api/toastServices";
import {
  uploadBanner,
  updateBanner,
  getBanners,
} from "../../../redux/slice/bannerSlice";
import AutocompleteInput from "../../extras/Autocomplete";

const BannerDialog = ({ salons }) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [imagePath, setImagePath] = useState("");
  const [selectedSalon, setSelectedSalon] = useState(null);
  useEffect(() => {
    debugger;
    if (dialogueData) {
      editData(dialogueData);
      setImagePath(dialogueData?.image);
      const salon = {
        _id: dialogueData?.salonId,
        name: dialogueData.salonName,
      };
      setSelectedSalon(salon);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger
    const addBanner = submitData(e);
    if (addBanner && selectedSalon) {
      const formData = objectToFormData({
        ...addBanner,
        salonId: selectedSalon._id,
      });

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, bannerId: dialogueData._id };
          response = await dispatch(updateBanner(payload)).unwrap();
          dispatch(getBanners());
        } else {
          response = await dispatch(uploadBanner(formData)).unwrap();
        }
        response.status
          ? dispatch(closeDialog())
          : DangerRight(response.message);
      } catch (err) {
        console.log("err", err);
        DangerRight(err.message);
      }
    }
  };

  const handleSalonSelect = (salon) => {
    setSelectedSalon(salon);
  };

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">Banner Dialog</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="bannerForm">
                <div className="row align-items-start formBody">
                  <div className="col-12 ">
                    <Input
                      type={`text`}
                      id={`title`}
                      name={`title`}
                      label={`Title`}
                      placeholder={`Title`}
                      errorMessage={`Enter Title`}
                    />
                  </div>
                  <div className="col-12 ">
                    <Input
                      type={`file`}
                      id={`image`}
                      name={`image`}
                      label={`Banner Image`}
                      errorMessage={`Image is required`}
                      accept={"image/*"}
                    />
                  </div>
                  <div className="col-12 ">
                    <AutocompleteInput
                      id="salon"
                      name="salon"
                      label="Salon"
                      placeholder="Search for a salon"
                      errorMessage="Please select a salon"
                      options={salons}
                      initialValue={dialogueData?.salonName}
                      onSelect={handleSalonSelect}
                    />
                  </div>
                  <div className="col-12 ">
                    <Input
                      type={`number`}
                      id={`duration`}
                      name={`duration`}
                      label={`Duration (in secs)`}
                      placeholder={`Enter duration`}
                      errorMessage={`Enter duration`}
                    />
                  </div>
                </div>
                <div className="row formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      className={`bg-gray text-light`}
                      text={`Cancel`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={` text-white m10-left`}
                      style={{ backgroundColor: "#1ebc1e" }}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerDialog;
