import React from "react";
import '../../assets/scss/custom/radiobutton.css'

const RadioButton = (props) => {
  const buttonType = props.type === 'approve' ? 'btn-approve' : 'btn-reject';

  return (
    <>
      <input
        type="radio"
        className="btn-check"
        name={props.name}
        id={props.id}
        autoComplete="off"
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <label
        className={`btn btn-icon ${buttonType} ${props.checked ? 'btn-active' : ''}`}
        htmlFor={props.id}
      >
        <i className={`bi bi-${props.icon}`}></i>
      </label>
    </>
  );
};

export default RadioButton;