import React, { useState, useEffect, useRef } from 'react';

const AutocompleteInput = (props) => {
  const {
    id,
    className,
    name,
    label,
    placeholder,
    errorMessage,
    options,
    onSelect,
    initialValue
  } = props;

  const [inputValue, setInputValue] = useState(initialValue || '');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
      const selectedOption = options.find(option => option.name === initialValue);
      if (selectedOption && onSelect) {
        onSelect(selectedOption);
      }
    }
  }, [initialValue, options]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
   
    if (value.trim() === '') {
      setError(true);
      setFilteredOptions([]);
      setShowDropdown(false);
    } else {
      setError(false);
      const filtered = options.filter(option =>
        option.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowDropdown(true);
    }
  };

  const handleOptionSelect = (option) => {
    debugger
    setInputValue(option.name);
    setShowDropdown(false);
    setError(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div className={`inputData autocomplete flex-row justify-content-start text-start`}>
      {label && (
        <label htmlFor={id} className="text-capitalize">
          {label}
        </label>
      )}
      <div className="position-relative" ref={dropdownRef}>
        <input
          type="text"
          name={name}
          className={`${className} rounded-2`}
          id={id}
          onChange={handleInputChange}
          value={inputValue}
          placeholder={placeholder}
        />
        {showDropdown && filteredOptions.length > 0 && (
          <ul className="dropMenu position-absolute w-100 mt-1 bg-white border rounded shadow-sm"
              style={{
                zIndex: 1000,
                maxHeight: '200px',
                overflowY: 'auto',
                left: 0,
                right: 0
              }}>
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionSelect(option)}
                className="cursor-pointer p-2 hover:bg-gray-100"
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <p id={`error-${name}`} className={`errorMessage text-capitalize text-danger ${error ? 'd-block' : 'd-none'}`}>
        {errorMessage}
      </p>
    </div>
  );
};

export default AutocompleteInput;