import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const Searching = ({ 
  data, 
  setData, 
  type, 
  serverSearching, 
  button, 
  debounceTime = 300,
  placeholder = "Searching for..." 
}) => {
  const [search, setSearch] = useState("");

  const debouncedServerSearch = useCallback(
    debounce((searchValue) => {
      serverSearching(searchValue);
    }, debounceTime),
    [serverSearching, debounceTime]
  );

  const handleSearch = useCallback((event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearch(searchValue);

    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().includes(searchValue);
            } else if (typeof itemValue === "number") {
              return itemValue.toString().includes(searchValue);
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      debouncedServerSearch(searchValue);
    }
  }, [type, data, setData, debouncedServerSearch]);

  const handleButtonClick = () => {
    if (button) {
      handleSearch({ target: { value: search } });
    }
  };

  return (
    <div className="col-7 ms-auto">
      <div className="inputData d-flex">
        <input
          type="search"
          id="search"
          placeholder={placeholder}
          className="bg-none m0-top"
          style={{ fontWeight: "500", height: "48px" }}
          value={search}
          onChange={handleSearch}
        />
        <div
          className="bg-theme p15-x midBox searchIcon"
          style={{ height: "48px" }}
          onClick={handleButtonClick}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 9C20.6421 9 24 12.3579 24 16.5M24.9882 24.9823L31.5 31.5M28.5 16.5C28.5 23.1275 23.1275 28.5 16.5 28.5C9.87258 28.5 4.5 23.1275 4.5 16.5C4.5 9.87258 9.87258 4.5 16.5 4.5C23.1275 4.5 28.5 9.87258 28.5 16.5Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Searching;