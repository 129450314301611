import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";

const initialState = {
  banners: [],
  salons:[],
  isLoading: false,
  isSkeleton: false,
};

export const getBanners = createAsyncThunk(
  "admin/banner/getAllBanners",
  async () => {
    return apiInstanceFetch.get(`admin/banner/getAllBanners`);
  }
);

export const uploadBanner = createAsyncThunk(
  "admin/banner/uploadBanner",
  async (formData) => {
    debugger
    return apiInstance.post(`admin/banner/uploadBanner`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }
);

export const approveBanner = createAsyncThunk(
  "admin/banner/approveBanner",
  async (id) => {
    return apiInstance.post(`admin/banner/approveBanner?bannerId=${id}`);
  }
);

export const updateBannerOrder = createAsyncThunk(
  "admin/banner/updateBannerOrder",
  async (orderData) => {
    return apiInstance.post(`admin/banner/updateBannerOrder`, { orderData });
  }
);

export const updateBanner = createAsyncThunk(
  "admin/banner/updateBanner",
  async ({ bannerId, formData }) => {
    // Append bannerId to formData
    formData.append('bannerId', bannerId);
debugger
    // Send the formData to the API without including the bannerId in the URL
    return apiInstance.put(`admin/banner/updateBanner`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }
);

  export const deleteBanner = createAsyncThunk("admin/banner/delete", async (id) => {
     
    return apiInstance.delete(`admin/banner/delete?bannerId=${id}`)
  })
  
export const getSalonsforBanner = createAsyncThunk(
    "admin/banner/getSalonsforBanner",
    async () => {
      return apiInstanceFetch.get(`admin/banner/getSalonsforBanner`);
    }
  );  
const bannerSlice = createSlice({
  name: "bannerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanners.pending, (state) => {
      state.isSkeleton = true;
    });
    builder.addCase(getBanners.fulfilled, (state, action) => {
      debugger
      state.banners = action?.payload?.data;
      state.isSkeleton = false;
    });
    builder.addCase(getBanners.rejected, (state) => {
      state.isSkeleton = false;
    });

    builder.addCase(uploadBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadBanner.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.banners.push(action?.payload?.data);
        Success("Banner uploaded successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(uploadBanner.rejected, (state) => {
      state.isLoading = false;
      DangerRight("Failed to upload banner");
    });

    builder.addCase(approveBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveBanner.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const updatedBanner = action?.payload?.data;
        const index = state.banners.findIndex(banner => banner._id === updatedBanner._id);
        if (index !== -1) {
          state.banners[index] = updatedBanner;
        }
        Success("Banner approved successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(approveBanner.rejected, (state) => {
      state.isLoading = false;
      DangerRight("Failed to approve banner");
    });

    builder.addCase(updateBannerOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBannerOrder.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.banners = action?.payload?.data;
        Success("Banner order updated successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(updateBannerOrder.rejected, (state) => {
      state.isLoading = false;
      DangerRight("Failed to update banner order");
    });
    builder.addCase(updateBanner.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(updateBanner.fulfilled, (state, action) => {
        if (action?.payload?.status) {
          const updatedBanner = action?.payload?.data;
          const index = state.banners.findIndex(banner => banner._id === updatedBanner._id);
          if (index !== -1) {
            debugger
            state.banners[index] = updatedBanner;
          }
          Success("Banner updated successfully");
        }
        state.isLoading = false;
      });
      builder.addCase(updateBanner.rejected, (state) => {
        state.isLoading = false;
        DangerRight("Failed to update banner");
      });
      builder.addCase(getSalonsforBanner.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getSalonsforBanner.fulfilled, (state, action) => {
        
          state.salons = action?.payload?.data.map(salon => ({
            _id: salon._id,
            name: salon.name
          }));
        
        state.isLoading = false;
      });
      builder.addCase(getSalonsforBanner.rejected, (state) => {
        state.isLoading = false;
        DangerRight("Failed to fetch salons");
      });
      builder.addCase(deleteBanner.pending, (state, action) => {
        state.isLoading = true;
      })
      builder.addCase(deleteBanner.fulfilled, (state, action) => {
       
          state.banners = state.banners.filter((banners) => banners._id !== action.meta.arg);
          Success("Banner Delete Successfully")
        
        state.isLoading = false;

      })
      builder.addCase(deleteBanner.rejected, (state, action) => {
        state.isLoading = false;
      })
    },
});

export default bannerSlice.reducer;